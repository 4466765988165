/*************************
 * Inject datas
 */
var Mustache = require('mustache');

let tmplMenu = document.getElementById('tmpl-menu').innerHTML;
let tmplSlides = document.getElementById('tmpl-slides').innerHTML

// imports images
import img1 from './img/1.jpg';
import img2 from './img/2.jpg';
import img3 from './img/3.jpg';
import img4 from './img/4.jpg';
import img5 from './img/5.jpg';
import img6 from './img/6.jpg';
import img7 from './img/7.jpg';
import img8 from './img/8.jpg';
import img9 from './img/9.jpg';
import img10 from './img/10.jpg';
import img11 from './img/11.jpg';

let view = {
    persons: [
        {
            nickname: 'Xibalba',
            twitch: '/ShupeTV',
            twitter: '@shupe_tv',
            title_fr: '-',
            title_en: '-',
            description_fr: "Au premier plan, un rocher avec un filet de pêche dessus. " +
                "Le rocher est au bord d\'une mer qui semble pourpre, comme gorgée de sang. " +
                "il y a une sirène qui sort de l\'eau en s\'appuyant sur le rocher, et elle " +
                "tend un coquillage comme pour le donner. La sirène est incrustée de coquillages, " +
                "avec un crabe sur la tête, et ses yeux n\'ont pas de pupille.",
            description_en: 'In the foreground, a rock with a fishing net on it. ' +
                'The rock is at the edge of a sea that seems purple, as if it were engorged with blood. ' +
                'There is a mermaid coming out of the water leaning on the rock, and she holds out ' +
                'a shell as if to give it away. The mermaid is encrusted with seashells, ' +
                'with a crab on her head, and her eyes have no pupils.',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Loukodil',
            twitch: '/LoukoTiko',
            twitter: '@LoukoTiko',
            title_fr: 'Malédiction de la mer',
            title_en: 'Curse of the Sea',
            image: img1,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Tikoati',
            twitch: '/Tikoati',
            twitter: '@Tikoati',
            title_fr: 'Appel des Tréfonds',
            title_en: 'Call from the Depths',
            description_fr: 'Une sirène bien fusionnée avec la mer, qui pêche dans une mer rouge, ' +
                'avec un crabe sur la tête et des jambes de moule, et qui tend un coquillage.',
            description_en: 'A mermaid well fused with the sea, who fishes ' +
                'in a red sea, with a crab on her head and mussel legs,' +
                ' and who holds out a seashell.',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Nimporte58',
            twitch: '/nimporte58',
            title_fr: "L'embrassement de la mer",
            title_en: 'Embrace the sea',
            image: img2,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'KillerGanon',
            twitch: '/killerganon2',
            twitter: '@killerganon',
            title_fr: 'Échidna affamée',
            title_en: 'Hungry Echidna',
            description_fr: "Une siréno-libellule aux ailes roses fait une DBZ-fusion " +
                "avec l'océan et laisse sa canne à pêche sans surveillance.",
            description_en: 'A mermaid dragonfly with pink wings makes a DBZ-fusion ' +
                'with the ocean and leaves its fishing rod unattended.',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'GraineDeCourge',
            twitch: '/GraineDeCourge',
            twitter: '@GraineDeCourges',
            title_fr: 'Communion de la mer',
            title_en: 'Communion with the Sea',
            image: img3,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Ardal',
            title_fr: 'Chant des Sirènes',
            title_en: 'Siren song',
            description_fr: 'Une Sirène qui fait un plongeon dans une mer déchaine ' +
                'avec la lumière du Grand Soleil derrière elle avec une canne à ' +
                'pêche en fond.',
            description_en: 'A Mermaid diving into a raging sea with the light ' +
                'of the Great Sun behind her with a fishing rod in the background.',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Faberstein',
            twitch: '/faberstein',
            twitter: '@faberstein',
            title_fr: "La canne à pêche du Jarl d'Undvik",
            title_en: 'Undvik\'s Jarl Fishing Rod',
            image: img4,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Kaneki_Yamori',
            title_fr: 'La suceuse de vers',
            title_en: 'The worm sucker',
            description_fr: "Une sirène avec présence d'une semi-calvitie sur son coté gauche," +
                " inclinée à 315° selon un rapporteur pris dans l'axe de la mer, sur un " +
                "rocher qui est en train de casser sous le poids de cette dame, qui essaye " +
                "de gober un ver de terre accroché à une canne à pêche.",
            description_en: '\n' +
                'A mermaid with the presence of a semi-bald spot on her left side, ' +
                'inclined at 315 ° according to a protractor taken in ' +
                'the axis of the sea, on a rock which is breaking under the ' +
                'weight of this lady, who is trying to swallow an earthworm ' +
                'hanging from a fishing rod.',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'ArtNhr',
            twitch: '/ArtNhr',
            twitter: '@ArthurNhr',
            title_fr: 'Appeau à Havfrue',
            title_en: 'Havfrue call',
            image: img5,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Rocksas',
            title_fr: 'Envoûtement des sirènes',
            title_en: 'Enchantment of sirens',
            description_fr: "C'est un pêcheur sur un rocher au milieu de la mer" +
                " qui va se faire attaquer par une sirène avec des ailes.",
            description_en: 'It\'s a fisherman on a rock in the middle of the ' +
                'sea who is going to be attacked by a mermaid with wings.',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Caemilech',
            twitch: '/Caemilech',
            title_fr: '-',
            title_en: '-',
            image: img6,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Raxyn',
            title_fr: 'Pêche fructueuse',
            title_en: 'Fruitful fishing',
            description_fr: 'On voit un homme à l\'air blasé en train de pêcher assis sur des' +
                ' rochers au milieu de la mer, à côté de lui se tient un panier de' +
                ' crabes, derrière lui une sirène fée qui le menace et dans le ciel' +
                ' un orage, la sirène aussi à l\'air blasé.',
            description_en: 'We see a jaded-looking man sinning sitting on rocks ' +
                'in the middle of the sea, next to him is a basket of crab, ' +
                'behind him a mermaid / fairy threatening him and in the sky' +
                ' a thunderstorm, the mermaid too looking jaded.',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Xhanthiax',
            twitter: '@xhantiax',
            title_fr: 'Fureur des flots',
            title_en: 'Fury of the waves',
            image: img7,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Trostid',
            title_fr: 'Échidna sanguinaire',
            title_en: 'Bloodthirsty Echidna',
            description_fr: "Deux éclairs jaunes entourent une sirène blanche dans" +
                " le ciel. Une sorcière est attachée avec son ragoût sur un gros" +
                " rocher au milieu de l'eau.",
            description_en: 'Two yellow lightnings surround a white mermaid in the sky. ' +
                'A witch is tied with her stew on a large rock in the middle of the water.',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'CodeZero01289',
            twitch: '/CodeZero01289',
            title_fr: 'Le supplice',
            title_en: 'The supplice',
            image: img8,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'AlexMadoc',
            twitch: '/AlexMadocBZH',
            title_fr: 'Parade de chimères',
            title_en: 'Chimeras parade',
            description_fr: "Une chimère vole dans l'orage au-dessus d'une" +
                " île. Sur cette île, un sorcier fait chauffer un chaudron.",
            description_en: 'A chimera flies in the storm over an island. ' +
                'On this island, a wizard is heating a cauldron.\n',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'sm0g0',
            twitch: '/sm0g0',
            title_fr: 'Chimère Bannie',
            title_en: 'Banished chimera',
            image: img9,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Crash_Dragon',
            title_fr: 'Offrande à la tempête',
            title_en: 'Offering to the storm',
            description_fr: "À l’ombre d’un grand chêne, un druide d’une île de" +
                " Skellige prépare une marmite en soufflant pour attiser le feu," +
                " sous la menace simultanée d’une tempête orageuse et l’attaque d’un dragon en furie.",
            description_en: 'In the shade of a large oak tree, a druid from an Isle of Skellige brews a pot,' +
                ' blowing to fan the fire, under the simultaneous threat of a thunder ' +
                'storm and the attack of a raging dragon.',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'Askatrof',
            twitch: '/askatrof',
            twitter: '@askatrof',
            title_fr: 'Dernier repas',
            title_en: 'Last meal',
            image: img10,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'ColonelChoupette',
            title_fr: 'Invocation nocturne',
            title_en: 'Nocturnal invocation',
            description_fr: "Par une nuit orageuse, devant un arbre, un homme" +
                " est penché sur un feu réchauffant une marmite. Un dragon" +
                " vert s'approche dans le ciel.",
            description_en: 'On a stormy night, in front of a tree, a man is ' +
                'leaning over a fire warming a pot. ' +
                'A green dragon approaches in the sky.',
            nick: function() {
                return this.nickname.toLowerCase();
            }
        },
        {
            nickname: 'CaptainFlixon',
            twitch: '/CaptainFlixon',
            title_fr: 'Pêcheuse Tuirseach',
            title_en: 'Fisherwoman Tuirseach',
            image: img11,
            nick: function() {
                return this.nickname.toLowerCase();
            }
        }
    ]
};

let menu = Mustache.render(tmplMenu, view);
document.getElementById('menu').innerHTML = menu;
let slides = Mustache.render(tmplSlides, view);
document.getElementById('slides').innerHTML = slides;

/*************************
 * Animations
 */
require('joshjs/css/animate.css');
import Josh from 'joshjs';

//const animations = new Josh({
//    initClass: 'shupetv-animate'
//});

/************************
 * Navigation
 */
import Pageable from 'pageable';

var anchors = document.querySelectorAll('#menu li');
new Pageable('#slides', {
    animation: 500,
    delay: 100,
    onFinish: update,
    events: {
        wheel: true, // enable / disable mousewheel scrolling
        mouse: false, // enable / disable mouse drag scrolling
        touch: false, // enable / disable touch / swipe scrolling
        keydown: true, // enable / disable keyboard navigation
    },
});

function update(data) {
    let that = this;
    anchors.forEach(function (anchor, i) {
        anchor.classList.toggle("active", i === that.index);
    });
}